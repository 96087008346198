<template>
  <div class="flex-col page">
    <!-- W -->
    <div class="flex-col justify-start self-stretch  section">
      <div class="tabs">
        <!-- <img class="image pos" src="../../img/3f16e645b14dc2fbd279b7069d3101bd.png" />
        <img class="image_2 pos_2" src="../../img/c7739287396e42eb3563ca8077cb6970.png" /> -->
        <img class="image pos" src="../../img/5778c6866623760614f74bb7ce908b7.png" />
        <span class="font_2 pos_7" :class="tabsIndex == 0 ? 'font_2_choose' : ''" @click="tabsClick(0)">关于我们</span>
        <span class="font_2 pos_8" :class="tabsIndex == 1 ? 'font_2_choose' : ''" @click="tabsClick(1)">产品</span>
        <!--  <span class="font_2 pos_8" :class="tabsIndex == 2 ? 'font_2_choose' : ''" @click="tabsClick(2)">服务案例</span> -->
        <span class="font_2 pos_9" :class="tabsIndex == 3 ? 'font_2_choose' : ''" @click="tabsClick(3)">技术与保障</span>
        <span class="font_2 pos_10" :class="tabsIndex == 4 ? 'font_2_choose' : ''" @click="tabsClick(4)">合作</span>
        <!-- <span class="text_3 pos_11">PIN XUN KE JI</span> -->
      </div>
      <el-carousel :interval="5000" arrow="always" height="982px" id="page">
        <el-carousel-item v-for="item in  bannerList " :key="item" >
          <img :src="item.url" alt="Slide Image" />
          <div class="flex-col justify-start items-center text-wrapper pos_14" :style="{border: '1px solid' + item.borderTextColor}"
            @click="tabsClick(4)">
            <span class="font_4 text_6 " :style="{color: item.borderTextColor}">立即咨询</span>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!--  -->
    <div id="group_2" style="padding-top: 50px; margin-top: -50px;">
      <div class="flex-col self-start group_2">
        <span class="self-start font_5">以技术力助力客户实现价值</span>
        <div class="flex-col justify-start items-start self-start section_5 mt-17">
          <div class="shrink-0 section_4"></div>
        </div>
        <div class="self-stretch group_3 mt-17">
          <span class="font_6">
            为客户提供高可用、高并发、高性能、高安全的一站式应用系统开发服务，包括WEB、手机APP、小程序、应用系统、大型商务系统、数据可视化平台、云计算运维、人工智能、大数据系统、大型网站系统建设等。
          </span>
        </div>
      </div>
    </div>

    <!--  -->
    <div class="flex-col self-stretch group_4">
      <!--  -->
      <div class="flex-col justify-start items-center relative group_5">
        <img class="image_7" src="../../img/f49d2358178bd9cb41ab5cf15ddb7535.png" />
        <div class="flex-row equal-division group_6">
          <div class="flex-col items-start group_7 equal-division-item">
            <img class="image_6" src="../../img/2c5b18f80e76d56f3cb1e630382d8870.png" />
            <div class="mt-26 group_11">
              <span class="font_7">
                应用
                <br />
              </span>
              <span class="font_7">系统开发</span>
            </div>
            <div class="mt-26 group_14">
              <span class="font_8">
                用于实现各类业务系统，如CRM、MIS、ERP、HIS、OA等的开发
              </span>
            </div>
          </div>
          <div class="flex-col items-start group_7 equal-division-item">
            <img class="image_6" src="../../img/39d6ea5242cef39ef604e0ee8dd5c856.png" />
            <div class="mt-26 group_12">
              <span class="font_7">
                小程序
                <br />
              </span>
              <span class="font_7">定制开发</span>
            </div>
            <div class="mt-26 group_14">
              <span class="font_8">
                基于微信、钉钉、抖音平台，定制开发小程序
              </span>
            </div>
          </div>
          <div class="flex-col items-start group_7 group_8">
            <img class="image_6" src="../../img/c16268069f1ca6fa205a3e08016537e3.png" />
            <div class="mt-26 group_12">
              <span class="font_7">
                移动端
                <br />
              </span>
              <span class="font_7">应用开发</span>
            </div>
            <div class="mt-26 group_15">
              <span class="font_8">
                包括Android、IOS应用开发，可基于业务需求进行定制化终端设备开发
              </span>
            </div>
          </div>
          <div class="flex-col items-start group_7 group_8">
            <img class="self-start image_6" src="../../img/1.png" />
            <div class="mt-26 group_12">
              <span class="font_7">
                物联网
                <br />
              </span>
              <span class="font_7">应用开发</span>
            </div>
            <div class="mt-26 group_15">
              <span class="font_8">
                提供了移动可视化开发、Web 可视化开发，帮助客户高效经济地完成物联网应用开发，设备、服务及应用开发
              </span>
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div>
        <div class="flex-col section_6" id="section_6" style="padding: 130px 184px 137px;; margin-top: -50px;">
          <div class="flex-row justify-between group_17">
            <div class="flex-col group_18">
              <span class="self-start font_10">我们的产品</span>
              <div class="flex-col justify-start items-start self-start section_8 mt-17">
                <div class="shrink-0 section_7"></div>
              </div>
              <div class="self-stretch group_19 mt-17">
                <span class="font_11">
                  以“成就客户”为核心理念，服务于客户、为客户创造价值，让企业得以明确未来的信息化发展战略，促
                  <br />
                </span>
                <span class="font_11">进客户智能化、智慧化，使每一款产品都在未来的战略中有所助益。</span>
              </div>
            </div>
            <!-- <div class="flex-row self-start group_20" @click=" productMore ">
              <span class="font_12">MORE</span>
              <img class="ml-10 shrink-0 image_9" src="../../img/8c6da03b7c9c414e0270d8fa5789d193.png" />
            </div> -->
          </div>
          <!--  -->
          <div class="flex-row mt-43 justify-between" style="margin-left: 42px;margin-right: 50px;">
            <div class="flex-col group_21 equal-division-item_2" @click="productTabsClick(0)">
              <div class="flex-row justify-center items-center relative group_22">
                <img class="image_10" src="../../img/24.png" />
                <span class="ml-13 self-start font_14 "
                  :class=" productTabs == 0 ? 'text_8_choose' : 'text_8' ">远程踏勘管理系统</span>
              </div>
            </div>
            <div class="flex-col group_21 equal-division-item_2" @click="productTabsClick(1)">
              <div class="flex-row justify-center items-center relative group_22">
                <img class="image_10" src="../../img/0d1e35cd7a4208c2acbbf2a24395470d.png" />
                <span class="ml-13 self-start font_14"
                  :class=" productTabs == 1 ? 'text_8_choose' : 'text_8' ">友推云·电商SaaS平台</span>
              </div>
            </div>
            <div class="flex-col group_21 equal-division-item_2" @click="productTabsClick(2)">
              <div class="flex-row justify-center items-center relative group_22">
                <img class="image_11" src="../../img/396fcbf93dec972f2bc9f92591c54a80.png" />
                <span class="ml-13 self-start font_14"
                  :class=" productTabs == 2 ? 'text_8_choose' : 'text_8' ">视频资源管理AI工具</span>
              </div>
            </div>
            <div class="flex-col group_21 equal-division-item_2" @click="productTabsClick(3)">
              <div class="flex-row justify-center items-center relative group_22">
                <img class="image_10" src="../../img/758108c701ff9d4fd070cd686c1fdf18.png" />
                <span class="ml-13 self-start font_14"
                  :class=" productTabs == 3 ? 'text_8_choose' : 'text_8' ">亥时学习平台</span>
              </div>
            </div>
            <div class="flex-col group_21 equal-division-item_2" @click="productTabsClick(4)">
              <div class="flex-row justify-center items-center relative group_22">
                <img class="image_10" src="../../img/a536abbfcddf71d0eb26ed678c6c797b.png" />
                <span class="ml-13 self-start font_14"
                  :class=" productTabs == 4 ? 'text_8_choose' : 'text_8' ">文明积分银行</span>
              </div>
            </div>
            <div class="flex-col group_21 equal-division-item_2" @click="productTabsClick(5)">
              <div class="flex-row justify-center items-center relative group_22">
                <img class="image_10" src="../../img/26.png" />
                <span class="ml-13 self-start font_14 " :class=" productTabs == 5 ? 'text_8_choose' : 'text_8' ">
                  邻里中心智慧平台</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-col relative group_23">
        <!--  -->
        <div class="flex-row self-stretch">
          <div class="flex-row flex-1 section_11">
            <div class="flex-col section_12">
              <span class="self-start font_7 text_12">{{ productList[productTabs].name }}</span>
              <span class="self-start font_15 text_13">
                {{ productList[productTabs].desc }}
              </span>
              <span class="self-start text_14">平台亮点：</span>
              <div class="flex-row self-start section_17" v-for="( item, index ) in  productList[productTabs].light "
                :key=" index ">
                <img class="shrink-0 image_14" src="../../img/6d2a7c8978bb208102df255fe1f24c32.png" />
                <span class="font_13 text_15">{{ item }}</span>
              </div>
              <div class="flex-row self-stretch group_27">
                <div class="flex-col justify-start items-center button"
                  @click="productDetail(productList[productTabs].url)"><span class="font_17">查看详情</span></div>
              </div>
            </div>
            <div class="flex-col justify-start section_13">
              <div class="flex-row space-between items-center relative section_14">
                <div class="flex-col justify-start items-center image-wrapper_2">
                  <img class="image_12" :src=" productList[productTabs].imgLeft " />
                </div>
                <img class="section_15_1" v-if=" productTabs == 2 " :src=" productList[productTabs].imgRight " />
                <img class="section_15 pos_19" v-else :src=" productList[productTabs].imgRight " />
              </div>
            </div>
          </div>
          <div class="ml-40 flex-col shrink-0 self-start  group_24">
            <div class="flex-row items-center self-stretch section_16">
              <img class="shrink-0 image_13" src="../../img/a93c3e22fa36a169fea8ccd4237cdf4d.png" />
              <span class="font_16 ml-13 phoneShow">15136339268</span>
            </div>
            <div class="weixinCode ml-6">
              <img class="image_15 pos_20 " src="../../img/2dcb55d0a8cf99defa6716e17b28d236.png" />
              <div class="ewm">
                <img src="../../img/2.png" />
              </div>
            </div>
            <div @click=" fixedClick " class=" mt-58 flex-col">
              <img class="self-end image_15 justify-end" src="../../img/a158971884072baf04733805f5ed23cb.png" />
            </div>
          </div>
        </div>
        <!--  -->
        <div class="flex-col LLM">
          <span class="self-start text">LLM打破传统界限无限释放灵感</span>
          <div class="flex-col self-stretch group">
            <img class="self-start image_2" src="../../img/575f04633e6f03fb9fa9c7aad61d21a9.png" />
            <div class="flex-col self-stretch mt-49">
              <div class="flex-row justify-between">
                <div @mouseenter="mouseenter(0)">
                  <div class="flex-col section " v-if=" LLEindex == 0 ">
                    <span class="self-start font text_2">LLM+BI</span>
                    <span class="self-start font_2 text_4">利用大模型丰富数据，从而获得更有意义的洞察结论</span>
                    <div class="flex-row items-center self-stretch group_5_1">
                      <div class="flex-col">
                        <div class="flex-row items-center">
                          <img class="image_3" src="../../img/17249eef67511f7c62f22c037325b272.png" />
                          <span class="ml-16 font_2">自动化报告生成</span>
                        </div>
                        <div class="mt-24 flex-row items-center">
                          <img class="image_3" src="../../img/17249eef67511f7c62f22c037325b272.png" />
                          <span class="ml-16 font_2">自然语言查询</span>
                        </div>
                        <div class="mt-24 flex-row items-center">
                          <img class="image_3" src="../../img/17249eef67511f7c62f22c037325b272.png" />
                          <span class="ml-16 font_2">数据解释</span>
                        </div>
                        <div class="mt-24 flex-row items-center">
                          <img class="image_3" src="../../img/17249eef67511f7c62f22c037325b272.png" />
                          <span class="ml-16 font_2">预测和推荐</span>
                        </div>
                        <div class="mt-24 flex-row">
                          <div class="flex-row items-center self-start group_7">
                            <img class="shrink-0 image_3" src="../../img/17249eef67511f7c62f22c037325b272.png" />
                            <span class="ml-16 font_2">客户服务</span>
                          </div>
                          <img class="ml-82 image_9" src="../../img/397cbd09d4c2a8ec1f9730a8ecba9d21.png" />
                        </div>
                      </div>
                      <div class="flex-col justify-start items-center relative group_6 ml-85">
                        <img class="image_8" src="../../img/15821c1425b61d574bff073d3e8b3928.png" />
                        <img class="image_4 pos" src="../../img/3.png" />
                      </div>
                    </div>
                  </div>
                  <div class="flex-col section_2" v-if=" LLEindex != 0 ">
                    <span class="self-start font">LLM+BI</span>
                    <div class=" flex-col self-stretch group_2_1">
                      <span class="font_3">
                        利用大模型丰富数据，从而获得更有意义的洞察结论
                      </span>
                    </div>
                    <img class="self-center image_5" src="../../img/7.png"
                      style="transform: translateY(12px) translateX(25px);" />
                  </div>
                </div>
                <div @mouseenter="mouseenter(1)">
                  <div class="flex-col section" v-if=" LLEindex == 1 ">
                    <div class="self-start font text_2">LLM+小程序</div>
                    <div class="self-start font_2 text_4">利用大模型丰富的功能，为小程序赋能，创造更多可能</div>
                    <div class="flex-row items-center self-stretch justify-between group_5_1">
                      <div class="flex-col">
                        <div class="flex-row items-center">
                          <img class="image_3" src="../../img/17249eef67511f7c62f22c037325b272.png" />
                          <span class="ml-16 font_2">AI对话游戏</span>
                        </div>
                        <div class="mt-24 flex-row items-center">
                          <img class="image_3" src="../../img/17249eef67511f7c62f22c037325b272.png" />
                          <span class="ml-16 font_2">AI群接龙助手</span>
                        </div>
                        <div class="mt-24 flex-row items-center">
                          <img class="image_3" src="../../img/17249eef67511f7c62f22c037325b272.png" />
                          <span class="ml-16 font_2"> AI虚拟人</span>
                        </div>
                      </div>
                      <div class="flex-col justify-start items-center relative group_6 ml-85">
                        <img class="image_8" src="../../img/15821c1425b61d574bff073d3e8b3928.png" />
                        <img class="image_4 pos" src="../../img/4.png" />
                      </div>
                    </div>
                  </div>
                  <div class="flex-col section_2 " v-if=" LLEindex != 1 ">
                    <span class="self-start font">LLM+小程序</span>
                    <div class="self-stretch group_2_1">
                      <span class="font_3">
                        利用大模型丰富的功能，为小程序赋能，创造更多可能
                      </span>
                    </div>
                    <img class="self-center image_5" src="../../img/8.png"
                      style="transform: translateY(12px) translateX(25px);" />
                  </div>
                </div>
                <div @mouseenter="mouseenter(2)">
                  <div class="flex-col section" v-if=" LLEindex == 2 ">
                    <div class="self-start font text_2">Ai自动写文章</div>
                    <div class="self-start font_2 text_4">基于深度学习与大数据提供深度理解能力与语言生成技术实现超低门槛快速生成文章</div>
                    <div class="flex-row items-center justify-between self-stretch group_5_1">
                      <div class="flex-col">
                        <div class="flex-row items-center">
                          <img class="image_3" src="../../img/17249eef67511f7c62f22c037325b272.png" />
                          <span class="ml-16 font_2">质量原创文章</span>
                        </div>
                        <div class="mt-24 flex-row items-center">
                          <img class="image_3" src="../../img/17249eef67511f7c62f22c037325b272.png" />
                          <span class="ml-16 font_2">火速跟进热点新闻</span>
                        </div>
                        <div class="mt-24 flex-row items-center">
                          <img class="image_3" src="../../img/17249eef67511f7c62f22c037325b272.png" />
                          <span class="ml-16 font_2">文本精准可信</span>
                        </div>
                        <div class="mt-24 flex-row items-center">
                          <img class="image_3" src="../../img/17249eef67511f7c62f22c037325b272.png" />
                          <span class="ml-16 font_2">一键快速生成</span>
                        </div>
                      </div>
                      <div class="flex-col justify-start items-center relative group_6 ml-85">
                        <img class="image_8" src="../../img/15821c1425b61d574bff073d3e8b3928.png" />
                        <img class="image_4 pos" src="../../img/5.png" />
                      </div>
                    </div>
                  </div>
                  <div class="flex-col section_3" v-if=" LLEindex != 2 ">
                    <span class="self-start font">Ai自动写文章</span>
                    <div class="self-stretch group_3">
                      <span class="font_4">
                        基于深度学习与大数据提供深度理解能力与语言生成技术实现超低门槛快速生成文章
                      </span>
                    </div>
                    <img class="self-end image_7" src="../../img/dd23e6cb821b60099cef4b761fcdaa49.png" />
                  </div>
                </div>
                <div @mouseenter="mouseenter(3)">
                  <div class="flex-col section" v-if=" LLEindex == 3 ">
                    <div class="self-start font text_2">大模型+CRM</div>
                    <div class="self-start font_2 text_4">客服智能化，数据可视化，客户体验个性化</div>
                    <div class="flex-row items-center justify-between self-stretch group_5_1">
                      <div class="flex-col">
                        <div class="flex-row items-center">
                          <img class="image_3" src="../../img/17249eef67511f7c62f22c037325b272.png" />
                          <span class="ml-16 font_2">强大的多模态通用系统</span>
                        </div>
                        <div class="mt-24 flex-row items-center">
                          <img class="image_3" src="../../img/17249eef67511f7c62f22c037325b272.png" />
                          <span class="ml-16 font_2">大模型prompt编写</span>
                        </div>
                        <div class="mt-24 flex-row items-center">
                          <img class="image_3" src="../../img/17249eef67511f7c62f22c037325b272.png" />
                          <span class="ml-16 font_2">定制化LLM应用</span>
                        </div>
                      </div>
                      <div class="flex-col justify-start items-center relative group_6 ml-85">
                        <img class="image_8" src="../../img/15821c1425b61d574bff073d3e8b3928.png" />
                        <img class="image_4 pos" src="../../img/10.png" />
                      </div>
                    </div>
                  </div>
                  <div class="flex-col section_4" v-if=" LLEindex != 3 ">
                    <span class="self-start font text_3">大模型+CRM</span>
                    <div class="self-stretch group_4">
                      <span class="font_4">
                        客服智能化，数据可视化，客户体验个性化
                      </span>
                    </div>
                    <img class="self-end image_6" src="../../img/6.png" />
                  </div>
                </div>
              </div>
              <div class="flex-row mt-29">
                <div class="flex-col section_5 equal-division-item">
                  <img class="self-center image_10" src="../../img/0ab8aacf12cbea669b2ecee2bc5a4836.png" />
                  <!-- <div class="mt-32 flex-col justify-start items-center self-stretch section_6">
                    <div class="group_8">
                      <span class="font_5">
                        懂你所言，答你所问，创你所需，
                        <br />
                      </span>
                      <span class="font_5">解你所难，学你所教</span>
                    </div>
                  </div> -->
                </div>
                <div class="flex-col section_5 equal-division-item ml-19">
                  <img class="self-center image_10" src="../../img/ae865c5efaf07ab50eaaaa089e20fd50.png" />
                  <!-- <div class="mt-32 flex-col justify-start items-center self-stretch section_7">
                    <div class="group_9">
                      <span class="font_5">
                        广泛成熟的实践落地
                        <br />
                      </span>
                      <span class="font_5">构建下一代智能应用</span>
                    </div>
                  </div> -->
                </div>
                <div class="flex-col section_5 equal-division-item ml-19">
                  <img class="self-center image_10" src="../../img/75a5eead95ccec6d9acf95e2da4ff460.png" />
                  <!-- <div class="mt-32 flex-col justify-start items-center self-stretch section_7">
                    <div class="group_9">
                      <span class="font_5">
                        寻求将能源转化为
                        <br />
                      </span>
                      <span class="font_5">智能的最优解</span>
                    </div>
                  </div> -->
                </div>
                <div class="flex-col section_5 equal-division-item ml-19">
                  <img class="self-center image_10" src="../../img/b716676382e219d6939103f2e547e766.png" />
                  <!-- <div class="mt-32 flex-col justify-start items-center self-stretch text-wrapper">
                    <span class="font_5 text_5">解决行业难题，释放AI生产力</span>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--  -->
        <!--  <div id="custom" style="padding-top: 50px; margin-top: -50px;">
          <div class="self-start font_5 text_19">客户案例</div>
          <img class="self-start image_16 image_17" src="../../img/575f04633e6f03fb9fa9c7aad61d21a9.png" />
          <div class="flex-row justify-between items-center self-stretch group_28">
            <div class="flex-row">
              <div class="flex-col justify-start items-center " :class="customIndex == 0 ? 'button_3' : 'text-wrapper_2'"
                @click="customTabsClick(0)">
                <span class="=" :class="customIndex == 0 ? 'font_14' : 'font_13'">精品推荐</span>
              </div>
              <div class="flex-col justify-start items-center  ml-9"
                :class="customIndex == 1 ? 'button_3' : 'text-wrapper_2'" @click="customTabsClick(1)">
                <span class=" text_20" :class="customIndex == 1 ? 'font_14' : 'font_13'">政务网站</span>
              </div>
              <div class="flex-col justify-start items-center  ml-9"
                :class="customIndex == 2 ? 'button_3' : 'text-wrapper_2'" @click="customTabsClick(2)">
                <span class=" text_20" :class="customIndex == 2 ? 'font_14' : 'font_13'">品牌网站</span>
              </div>
              <div class="flex-col justify-start items-center  ml-9"
                :class="customIndex == 3 ? 'button_3' : 'text-wrapper_2'" @click="customTabsClick(3)">
                <span class=" text_20" :class="customIndex == 3 ? 'font_14' : 'font_13'">手机网站</span>
              </div>
              <div class="flex-col justify-start items-center  ml-9"
                :class="customIndex == 4 ? 'button_3' : 'text-wrapper_2'" @click="customTabsClick(4)">
                <span class=" text_20" :class="customIndex == 4 ? 'font_14' : 'font_13'">系统开发</span>
              </div>
              <div class="flex-col justify-start items-center  ml-9"
                :class="customIndex == 5 ? 'button_3' : 'text-wrapper_2'" @click="customTabsClick(5)">
                <span class=" text_20" :class="customIndex == 5 ? 'font_14' : 'font_13'">响应式网站</span>
              </div>
              <div class="flex-col justify-start items-center  ml-9"
                :class="customIndex == 6 ? 'button_3' : 'text-wrapper_2'" @click="customTabsClick(6)">
                <span class=" text_20" :class="customIndex == 6 ? 'font_14' : 'font_13'">微信小程序</span>
              </div>
            </div>
            <div class="flex-row" @click="customMore">
              <span class="font_18">MORE</span>
              <img class="ml-10 shrink-0 image_9" src="../../img/edd8e3ccc1c75fbf11736c864c623da4.png" />
            </div>
          </div>
          <div class="grid" v-if="customList[customIndex]">
            <div class="flex-col justify-start relative grid-item" v-for="(item, index) in customList[customIndex].arr"
              :key="index">
              <div class="flex-col relative section_18">
                <div class="flex-row justify-between self-stretch">
                  <span class="font_4">{{ item.name }}</span>
                  <img class="image_19 image_20" :src="item.type" />
                </div>
                <span class="self-start font_15 text_21">{{ item.desc }}</span>
                <div class="flex-row justify-between items-center self-stretch group_31">
                  <span class="font_19">查看更多</span>
                  <img class="image_9 image_23" src="../../img/495cbd9fb98c54126495160ced3cd9aa.png" />
                </div>
              </div>
              <img class="image_18 pos_21" :src="item.src" />
            </div>
          </div>
        </div>-->
      </div>
    </div>
    <!--  -->
    <div class="flex-col self-stretch group_32">
      <div class="flex-col section_20">
        <div class="flex-col list-item ">
          <span class="self-start font ">为不同领域提供更加垂直的行业解决方案</span>
          <span class="self-start font_2 text_2">每个案例都有一段故事，我们努力将每个故事演绎成经典!</span>
          <div class="flex-row self-stretch group">
            <div class="flex-col shrink-0 self-start " style="margin-top:10px">
              <div class="flex-col justify-start items-center self-stretch "
                :class=" resultIndex == 0 ? 'text-wrapper_choose' : 'text-wrapper' " @click="resultTabsClick(0)">
                <span class="font_4">智慧社区</span>
              </div>
              <div class="text_4 flex-col justify-start items-center self-stretch "
                :class=" resultIndex == 1 ? 'text-wrapper_choose' : 'text-wrapper' " @click="resultTabsClick(1)">
                <span class="font_4 ">智慧环卫</span>
              </div>
              <div class=" text_4 flex-col justify-start items-center self-stretch "
                :class=" resultIndex == 2 ? 'text-wrapper_choose' : 'text-wrapper' " @click="resultTabsClick(2)">
                <span class="font_4 ">食品药品安全</span>
              </div>
            </div>
            <div class="flex-col flex-1 self-start group_3">
              <div class="flex-col items-start">
                <span class="font_3"> {{ resultList[resultIndex].name }}</span>
                <span class="mt-28 font_2 text_3">
                  {{ resultList[resultIndex].desc }}
                </span>
              </div>
              <div class="mt-60 flex-row justify-between group_4">
                <div class="flex-col group_7 view" v-for="( item, index ) in  resultList[resultIndex].arr ">
                  <img class="self-center image_2" :src=" item.src " />
                  <span class="self-center font_5 text_5">{{ item.name }}</span>
                  <div class="self-stretch group_9">
                    <span class="font_6">
                      {{ item.desc }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="flex-col self-stretch group_47">
      <!--  -->
      <div class="flex-col justify-start relative group_50">
        <div class="flex-col justify-start section_26">
          <div class="flex-col section_27" id="group_47">
            <div class="flex-col items-center">
              <span class="font_10">联系我们</span>
              <img class="image_16 image_48" src="../../img/1403fc85952a2c22cbdeaa756185ca70.png" />
              <span class="font_17 text_59">行必用心 事必有应</span>
            </div>
            <div class="flex-row mt-79">
              <div class="flex-col items-center equal-division-item_4 section_28">
                <img class="image_49" src="../../img/30c459f4a9e3556c71fa70ed5c73b283.png" />
                <span class="font_34 mt-21 text_62_1">电话咨询</span>
                <span class="font_34 text_62 mt-29">15136339268</span>
              </div>
              <div class="flex-col items-center relative equal-division-item_4 equal-division-item_5 ml-73">
                <div class="codeShow flex-col items-center">
                  <img class="image_49" src="../../img/d331c99d2984f9df17ff96acb83543b6.png" />
                  <div class="font_34 mt-21">微信咨询</div>
                </div>
                <img src="../../img/2.png" class="ewmCode" />
              </div>
              <div class="flex-col equal-division-item_4 section_30 ml-73">
                <img class="self-center image_49" src="../../img/952f36c52a8e884743a53092f16a17b5.png" />
                <span class="font_34_1 mt-21">
                  深圳市福田区华富街道笋岗西路3072-1号
                </span>
              </div>
            </div>
          </div>
        </div>
        <div id="section_25" class="flex-row justify-between section_25 pos_30">
          <div class="flex-row items-start self-center">
            <div class="flex-col">
              <div class="flex-col items-start">
                <span class="font_5">服务保障</span>
                <img class="image_16 image_44" src="../../img/575f04633e6f03fb9fa9c7aad61d21a9.png" />
                <span class="font_13 text_51">根据您的需求选择可定制服务，更高效管理您的应用</span>
              </div>
              <div class="flex-row mt-61">
                <div class="flex-col items-center shrink-0 self-start">
                  <img class="image_47" src="../../img/aa83f7373fc558fc83100396f00b9eb8.png" />
                  <span class="font_31 text_52">升级服务</span>
                  <div class="group_55">
                    <span class="font_33">
                      软件升级
                      <br />
                    </span>
                    <span class="font_33">
                      响应升级
                      <br />
                    </span>
                  </div>
                </div>
                <div class="flex-col items-center shrink-0 group_51 ml-162">
                  <img class="image_35" src="../../img/d93752beb28f0cdf920f8318c41beaf5.png" />
                  <span class="font_31 text_53">增值服务</span>
                  <div class="group_56">
                    <span class="font_28">
                      性能调优
                      <br />
                    </span>
                    <span class="font_28">定制开发</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex-row group_52 ml-117">
              <div class="flex-col shrink-0">
                <div class="flex-row">
                  <div class="flex-col items-center shrink-0">
                    <img class="image_35" src="../../img/98ed5b9bee141d69100272abe5a52ff4.png" />
                    <span class="font_31 text_54">专属服务</span>
                    <div class="group_57">
                      <span class="font_28">
                        服务专员
                        <br />
                      </span>
                      <span class="font_28">
                        全年驻场
                        <br />
                      </span>
                      <span class="font_28">
                        定期驻场
                        <br />
                      </span>
                      <span class="font_28">运维支持</span>
                    </div>
                  </div>
                  <div class="flex-col items-center shrink-0 self-start group_53 ml-162">
                    <img class="image_45" src="../../img/8297feafed45e646ffe9a9925af527f3.png" />
                    <span class="font_32 text_55">专业培训服务</span>
                    <div class="group_58">
                      <span class="font_28">
                        专属培训
                        <br />
                      </span>
                      <span class="font_28">
                        产品培训
                        <br />
                      </span>
                      <span class="font_28">系统运维</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex-col items-center shrink-0 self-start ml-106">
                <img class="image_35" src="../../img/715d9da50e25c4dc4f86bff075e9d1d3.png" />
                <span class="font_32 text_56">咨询与方案</span>
                <div class="group_59">
                  <span class="font_28">
                    管理咨询
                    <br />
                  </span>
                  <span class="font_28">
                    业务咨询
                    <br />
                  </span>
                  <span class="font_28">
                    IT规划
                    <br />
                  </span>
                  <span class="font_28">
                    方案落地
                    <br />
                  </span>
                </div>
              </div>
              <div class="flex-col items-center shrink-0 self-start ml-106">
                <img class="image_35" src="../../img/064087c0e03737335e856e3da373df79.png" />
                <span class="font_32 text_56">7x12小时运维服务</span>
                <div class="group_59">
                  <span class="font_28">
                    <br />
                  </span>
                  <span class="font_28">
                    保证业务稳定
                    <br />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="flex-col items-center self-start group_54">
            <img class="image_46" src="../../img/064087c0e03737335e856e3da373df79.png" />
            <span class="font_32 text_57">7x12小时运维服务</span>
            <span class="font_13 text_58">保证业务稳定</span>
          </div> -->
        </div>
      </div>
      <!--  -->
      <div class="flex-row justify-between section_31">
        <div class="flex-col self-center group_62">
          <img class="self-center image_50" src="../../img/25bc506b1a2d38ef4092bcfd5b3c150f.png" />
          <span class="self-center font_22 text_69">深圳亥时科技有限公司</span>
          <span class="self-center text_73">15136339268</span>
          <div class="self-stretch group_66">
            <span class="font_17_1 text_77">
              地址： 深圳市福田区华富街道笋岗西路3072-1号
            </span>
          </div>
        </div>
        <div class="self-center section_32"></div>
        <div class="flex-col items-start">
          <span class="font_35">产品体系</span>
          <span class="font_17 text_66">远程踏勘管理系统</span>
          <span class="font_17 text_70">友推云·电商SaaS平台</span>
          <span class="font_17 text_74">视频资源管理AI工具</span>
          <span class="font_17 text_80">亥时学习平台</span>
          <span class="font_17 text_81">文明积分银行</span>
          <span class="font_17 text_80">邻里中心智慧平台</span>
        </div>
        <div class="flex-col items-start">
          <span class="font_35 text_64">解决方案</span>
          <span class="font_17 text_67">智慧社区</span>
          <span class="font_17 text_71">智慧环卫</span>
          <span class="font_17 text_75">食品药品安全</span>
          <!-- <span class="font_17 text_81">线上展会</span> -->
        </div>
        <!-- <div class="flex-col items-start self-start group_63">
          <span class="font_35 text_65">服务体系</span>
          <span class="font_17 text_68">软件开发</span>
          <span class="font_17 text_72">信息化咨询</span>
          <span class="font_17 text_76">企业上云</span>
        </div> -->
        <div class="self-center section_32"></div>
        <div class="flex-col self-center">
          <!-- <div class="flex-row">
            <div class="flex-col justify-start relative group_64">
              <div class="group_65"></div>
              <img class="image_52 pos_33" src="../../img/b9138baec0eb743ec366219cb10c477c.png" />
              <img class="image_51 pos_31" src="../../img/c71d4904444746a4da7224d79a321bd2.png" />
            </div>
            <div class="ml-24 flex-col justify-start relative group_64">
              <div class="group_65"></div>
              <div class="flex-col justify-start items-center image-wrapper_3 pos_34">
                <img class="image_13" src="../../img/6a905240878593e4810d0748bb252371.png" />
              </div>
              <img class="image_51 pos_32" src="../../img/c71d4904444746a4da7224d79a321bd2.png" />
            </div>
          </div>
          <div class="mt-26 flex-row group_67">
            <span class="font_36 text_78">微信小程序</span>
            <span class="ml-66 font_36 text_79">微信公众号</span>
          </div> -->
        </div>
      </div>
      <!--  -->
      <div class="flex-col justify-start items-center text-wrapper_4">
        <span class="font_19 text_83">Copyright © 2023 深圳亥时科技有限公司 版权所有豫ICP备18009817号</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      tabsIndex: -1,
      bannerList: [
        {
          url: require('../../img/27.png'),
          borderTextColor: '#f8faff',
        }, {
          url: require('../../img/28.png'),
          borderTextColor: '#000',
        }
      ],
      productTabs: 0,
      productList: [{
        name: '远程踏勘管理系统',
        desc: '远程不见面，办理更及时，为企业及群众提供更加专业精准高效的神品服务',
        light: ['移动接入高效快捷', '统一标准公开透明', '“零接触”防疫有保障', '云端存储可靠安全'],
        imgLeft: require('../../img/b38e464b4f7e00d7dfb54f244f75746c.png'),
        imgRight: require('../../img/7f4f9ce1f033ffaca189e9e56eba6292.png'),
        url: 'http://www.hnpinxun.cn/yuanchengtakan.html'
      }, {
        name: '友推云·电商SaaS平台',
        desc: '新零售的核心在于线上线下和配送的结合，解决方案包含电商平台+连锁门店+ERP+IM等，帮助商家打破空间局限，提高门店评效，告别混乱管理，打通进销存',
        light: ['专属辅助运营团队', '多元化营销玩法', '线上线下打通融合', '兼容加盟、直营多套体系'],
        imgLeft: require('../../img/11.png'),
        imgRight: require('../../img/12.png'),
        url: 'http://yt.hnpinxun.cn/'
      }, {
        name: '视频资源管理AI工具',
        desc: '针对中小型企业的员工培训难题，提供多行业解决方案',
        light: ['节约成本', '统一标准公开透明', '“零接触”防疫有保障', '云端存储可靠安全'],
        imgLeft: require('../../img/13.png'),
        imgRight: require('../../img/14.png'),
        url: 'http://fastrms.hnpinxun.cn/'
      }, {
        name: '亥时学习平台',
        desc: '一站式的数字化学习解决方案，为中小型企业提供多行业培训解决方案，助力企业实现员工培训和学习的全面转型',
        light: ['多行业解决方案', '一站式学习平台', '个性化学习体验', '强大的管理和评估功能'],
        imgLeft: require('../../img/15.png'),
        imgRight: require('../../img/16.png'),
        url: 'http://hsxx.hnpinxun.cn/'
      }, {
        name: '文明积分银行',
        desc: '通过平台积分任务,设置合理的积分获取,引导广大村民转变生产生活方式，养成文明生活习惯',
        light: ['常态化服务和管理模式', '平台积分任务', '宣传教育基础', '创新形式和持久性'],
        imgLeft: require('../../img/25.png'),
        imgRight: require('../../img/23.png'),
        url: 'http://jifen.hnpinxun.cn/'
      }, {
        name: '邻里中心智慧平台',
        desc: '以“人、地、事、物、情、组织”等建设信息为基本内容，搭建资源共享、联动共管、全面覆盖、高速快捷、科学治理和服务民生的社区管理信息平台',
        light: ['一站服务百姓民生', '一键全局决策分析', '一体覆盖全部业务', '一网融合五级生态'],
        imgLeft: require('../../img/21.png'),
        imgRight: require('../../img/22.png'),
        url: 'https://linli.hnpinxun.cn/'
      },],
      resultList: [{
        name: '智慧社区',
        desc: '智慧社区系统结合了信息技术、物联网和智能化技术，旨在提升社区管理效率、改善居民生活质量，打造更智能、便捷和安全的社区环境。',
        arr: [{
          name: '邻里中心',
          src: require('../../img/ff37b836686af88fd2cf072a435e6c3e.png'),
          desc: ' 以“人、地、事、物、情、组织”等建设信息为基本内容，搭建资源共享、联动共管、全面覆盖、高速快捷、科学治理和服务民生的社区管理信息平台。',
        }, {
          name: '智慧养老',
          src: require('../../img/31147f015363eb5fe8f28d2a4e083193.png'),
          desc: '汇集多种先进技术，智能管理养老业务,实现全终端覆盖，老人信息统一管理，养老运营可视化。'
        }, {
          name: '党员双报到',
          src: require('../../img/8acf7ceeda95a7ec80f32582d2885fce.png'),
          desc: '党员社区“双报到” 服务群众零距离为充分发挥党组织战斗堡垒和党员先锋模范作用,同时有效解决“双报到”工作开展评价难、统计难等问题。'
        }, {
          name: '网格化管理',
          src: require('../../img/16eb617da24c9ea824ef58caf8e67e78.png'),
          desc: '通过运用数字化、信息化手段，以市、区（县）、乡镇（街道）、社区、网格为区域范围，通过加强对单元网格的主动排查，实现上下联动，资源共享的社会治理新模式。'
        }]
      }, {
        name: '智慧环卫',
        desc: '集环卫人,车,物,事一体化管理的平台 涵盖视频安防、车辆定位、设备运维、作业管理等场景功能,提升城市环卫服务品质和管理效能',
        arr: [
          {
            name: '环卫一张图',
            src: require('../../img/1b7ebb669481fd44eb00a3f2352b042f.png'),
            desc: '通过设施、人、车、物、事件、作业实况等多维度融合，将总体环卫运营情况通过数字地图+数据看板的方式直观展示，实现智慧环卫运营全屏可视化和数字化管理'
          }, {
            name: '环卫信息管理',
            src: require('../../img/abdf0a6672ea7bfc2178f2517625318d.png'),
            desc: '解决人员调度不及时，考勤管理不到位的问题。系统应用物联网技术，实现由“人管人”、“走动式”、“行政性”管理向机制化、制度化、信息化管理的方式转变'
          }, {
            name: '环卫车辆管理',
            src: require('../../img/abdf0a6672ea7bfc2178f2517625318d.png'),
            desc: ' 一站式解决环卫车辆的调度和指挥、环卫车辆的安全监管、环卫车辆的仪表盘和状态监控、环卫车辆的维护和保养等问题 '
          },
        ]
      }, {
        name: '食品药品安全',
        desc: '为食品药品安全保驾护航，实现大数据精准分析，智能监管',
        arr: [
          {
            name: '食品安全云',
            src: require('../../img/4e5951a2686ffabffd5319f4ba9a8fe5.png'),
            desc: '依托云计算平台、物联网技术等先进技术，形成了以大数据中心为核心，多种数据库为支撑，帮助监管部门实现智能监管'
          }, {
            name: '食品安全监管',
            src: require('../../img/31933348127171c4a63366d15331dd4c.png'),
            desc: '以提升数据效能为切入点建设食品安全风险数据可视化平台，通过抽检监测结果分析，形成食品安全风险预警机制，辅助食品安全风险的分析研判和监督检查相关决策'
          }, {
            name: '食品安全巡查',
            src: require('../../img/b48b56b23f474d98c0c40eedafa70aab.png'),
            desc: '建统一的大平台、大数据、大系统、大服务为着力点，全面整合原工商、食药监、质监、知识产权业务，形成统一的市场监管一体化应用体系'
          }, {
            name: '校园食堂管理',
            src: require('../../img/1112babbd96f4990d6e883714286cb04.png'),
            desc: '通过运用数字化、信息化手段，以市、区（县）、乡镇（街道）、社区、网格为区域范围，通过加强对单元网格的主动排查，实现上下联动，资源共享的社会治理新模式'
          }
        ]
      }],
      resultIndex: 0,
      customIndex: 0,
      customList: [{
        arr: [{
          name: '龙门文创院',
          desc: '文创服务管理网站',
          src: require('../../img/4c76237e0b84dc7d69802b3898cb2e02.png'),
          type: require('../../img/a2753329abb6050338ac550fcf1d7014.png')
        },
        {
          name: "运输机场跑道表面状况检测系统",
          desc: '机场服务管理系统',
          src: require('../../img/7d4e97fcc9b1c4fc8f7fc98168d0338f.png'),
          type: require('../../img/6a83a74f7819548d5b99c14611b8082b.png')
        }, {
          name: "中原国际文化旅游产业博览会",
          desc: '文化旅游服务平台',
          src: require('../../img/5032971cd0dd3c954ee80a3e51c0207a.png'),
          type: require('../../img/a2753329abb6050338ac550fcf1d7014.png')
        }, {
          name: "洛阳民族宗教事务局",
          desc: '政务服务网站',
          src: require('../../img/3fca848aa9dac8aecc9049cccc9e45e8.png'),
          type: require('../../img/16d6b7268e392000c120de523cd73f5a.png')
        }, {
          name: "先导家具",
          desc: '家具服务网站',
          src: require("../../img/8af65bf0f67ce2ce2155e7a0f7e560a9.png"),
          type: require('../../img/6a83a74f7819548d5b99c14611b8082b.png')
        }, {
          name: "风向标",
          desc: '商标服务平台',
          src: require('../../img/101c341c2dc083bf598189e5bc279eb0.png'),
          type: require('../../img/a2753329abb6050338ac550fcf1d7014.png')
        },]
      }],
      LLEindex: 0
    };
  },
  
  mounted() {
    this.scalePage();
    window.addEventListener('resize', this.scalePage);
    window.addEventListener('scroll', this.handleScroll, true);
    if (this.$route.query.tabsIndex) {
      this.tabsClick(this.$route.query.tabsIndex)
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll, true)
  },
  methods: {
    tabsClick(e) {

      if (e == 0) {
        document.getElementById('group_2').scrollIntoView({ behavior: "smooth"});
      } else if (e == 1) {
        document.getElementById('section_6').scrollIntoView({ behavior: "smooth",});
      } else if (e == 2) {
        document.getElementById('custom').scrollIntoView({ behavior: "smooth",});
      } else if (e == 3) {
        document.getElementById('section_25').scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
      } else if (e == 4) {
        document.getElementById('group_47').scrollIntoView({  behavior: "smooth",block: "start", inline: "center" });
      }
      this.tabsIndex = e
    },
    handleScroll() {
      this.$nextTick(() => {
        var top = document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset
        if (top < 886) {
          this.tabsIndex = -1
        } else if (top > 886 && top < 1700) {
          this.tabsIndex = 0
        } else if (top >= 1700 && top < 2570) {
          this.tabsIndex = 1
        } else if (top > 3450 && top < 4540) {
          this.tabsIndex = 2
        } else if (top > 5100 && top < 5710) {
          this.tabsIndex = 3
        } else if (top >= 5710) {
          this.tabsIndex = 4
        }
      })
    },
    productTabsClick(e) {
      this.productTabs = e
    },
    resultTabsClick(e) {
      this.resultIndex = e
    },
    fixedClick(e) {
      document.getElementById('page').scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
    },
    customTabsClick(e) {
      this.customIndex = e
    },
    customMore() {
      this.$router.push('/custom')
    },
    productMore() {
      this.$router.push('/product')
    },
    productDetail(e) {
      window.open(e, "_blank");
    },
    mouseenter(e) {
      this.LLEindex = e
    }
  },
};
</script>

<style scoped lang="scss">

.mt-27 {
  margin-top: 27px;
}

.mt-63 {
  margin-top: 63px;
}

.mt-17 {
  margin-top: 17px;
}

.mt-43 {
  margin-top: 43px;
}

.ml-21 {
  margin-left: 21px;
}

.ml-3 {
  margin-left: 3px;
}

.ml-19 {
  margin-left: 19px;
}

.ml-13 {
  margin-left: 13px;
}

.mt-89 {
  margin-top: 89px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-79 {
  margin-top: 79px;
}

.ml-9 {
  margin-left: 9px;
}

.ml-35 {
  margin-left: 35px;
}

.mt-13 {
  margin-top: 13px;
}

.mt-11 {
  margin-top: 11px;
}

.ml-226 {
  margin-left: 226px;
}

.mt-29 {
  margin-top: 29px;
}

.mt-21 {
  margin-top: 21px;
}

.ml-73 {
  margin-left: 73px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-61 {
  margin-top: 61px;
}

.ml-117 {
  margin-left: 117px;
}

.ml-162 {
  margin-left: 162px;
}

.ml-106 {
  margin-left: 106px;
}

.page {
  background-color: #ffffff;
  width: 100%;
  overflow-y: auto;
  overflow-x: auto;
  height: 100%;
  min-width: 1900px;

  .tabs {
    height: 98px;
    background: #000000;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 999;
  }

  .section {
    .image {
      width: 210px;
      // height: 121px;
    }

    .pos {
      position: absolute;
      left: 240px;
      top: 21px;
    }

    .image_2 {
      width: 94px;
      height: 23px;
    }

    .pos_2 {
      position: absolute;
      left: 308px;
      top: 29px;
    }

    .font_2 {
      font-size: 16px;
      font-family: PingFang;
      line-height: 16px;
      color: #ffffff;
      cursor: pointer;
    }

    .font_2_choose {
      padding-bottom: 8px;
      border-bottom: 3px solid #2c89fd;
    }

    .font_2:hover {
      color: #2c89fd;
    }


    .pos_6 {
      position: absolute;
      right: 575px;
      top: 39px;
    }

    .pos_7 {
      position: absolute;
      right: 440px;
      top: 39px;
    }

    .pos_8 {
      position: absolute;
      right: 354px;
      top: 39px;
    }

    .pos_9 {
      position: absolute;
      right: 225px;
      top: 39px;
    }

    .pos_10 {
      position: absolute;
      right: 147px;
      top: 39px;
    }

    .text {
      line-height: 20px;
    }

    .pos_4 {
      position: absolute;
      right: 246px;
      top: 37px;
    }

    .text_3 {
      color: #ffffff;
      font-size: 14px;
      font-family: PingFang;
      line-height: 12px;
    }

    .pos_11 {
      position: absolute;
      left: 308px;
      top: 55px;
    }


    .group {
      text-align: center;
      width: 1024px;

      .font_3 {
        font-size: 72px;
        font-family: FZZDHJW;
        letter-spacing: 7px;
        line-height: 140px;
      }

      .text_4 {
        color: #ffffff;
      }

      .text_5 {
        color: #2c89fd;
        white-space: nowrap;
      }
    }



    .text-wrapper {
      padding: 14px 0;
      border-radius: 24px;
      width: 180px;

      .text_6 {
        font-weight: 700;
        line-height: 19px;
      }
    }

    .pos_14 {
      position: absolute;
      left: 50%;
      top: 70%;
      transform: translateX(-50%);
      cursor: pointer;
    }

    .pos_14:hover {
      .font_4 {
        color: #2c89fd
      }
    }
  }

  // 
  .group_2 {
    margin-left: 240px;
    margin-top: 89px;
    width: 755px;

    .section_5 {
      background-color: #e6e6e6;
      border-radius: 1.5px;
      width: 240px;

      .section_4 {
        background-color: #2c89fd;
        width: 70px;
        height: 5px;
      }
    }

    .group_3 {
      line-height: 30px;
    }
  }

  .group_4 {
    margin-top: 53px;

    .group_5 {
      padding-top: 289px;

      .image_7 {
        width: 100vw;
        height: 9.5521vw;
        margin-top: 60px;
      }

      .image-wrapper {
        padding: 409px 0 51px;
        background-color: #2c89fd;
        border-radius: 5px;
        width: 320px;

        .image_8 {
          margin-left: 41px;
          width: 48px;
          height: 10px;
        }
      }

      .pos_17 {
        position: absolute;
        right: 240px;
        top: 0;
      }

      .equal-division {
        position: absolute;
        left: 50%;
        top: 0px;
        transform: translateX(-51%);

        .group_7:hover {
          background: #2C89FD;

          .font_7 {
            color: #fff;
          }

          .font_8 {
            color: #fff;
          }
        }

        .group_7 {
          width: 320px;
          height: 470px;
          margin-right: 20px;

          .image_6 {
            width: 80px;
            height: 80px;
          }

          .group_11 {
            line-height: 36px;
          }

          .group_14 {
            line-height: 26px;
          }

          .group_12 {
            line-height: 36px;
            width: 98px;
          }

          .group_15 {
            line-height: 26px;
          }

          .font_8 {
            font-size: 16px;
            font-family: MicrosoftYaHei;
            line-height: 26px;
            color: #666666;
          }

          .group_10 {
            width: 100px;

            .group_13 {
              margin-left: 2px;
              line-height: 36px;
            }
          }

          .group_16 {
            margin-left: 2px;
            line-height: 26px;

            .font_9 {
              font-size: 16px;
              font-family: MicrosoftYaHei;
              line-height: 26px;
              color: #ffffff;
            }
          }
        }

        .equal-division-item {
          padding: 59px 39px 24px 41px;
        }

        .group_8 {
          padding: 59px 39px 24px 41px;
          width: 347px;
        }
      }
    }

    .section_6 {

      background-image: url('../../img/f864fd365f6064e42285bbd76f86fded.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;

      .group_17 {
        padding-left: 56px;
        padding-right: 57px;

        .group_18 {
          width: 741px;

          .section_8 {
            margin-left: 3px;
            background-color: #ffffff80;
            border-radius: 1.5px;
            width: 240px;

            .section_7 {
              background-color: #ffffff;
              width: 70px;
              height: 5px;
            }
          }

          .group_19 {
            margin-left: 3px;
            line-height: 30px;
          }
        }

        .group_20 {
          margin-top: 107px;
          cursor: pointer;
        }

      }

      .group_21 {
        .group_22:hover {
          .text_8 {
            color: #2c89fd;
          }
        }

        .text_8_choose {
          color: #2c89fd;
        }

        .group_22 {
          padding: 12px 14px 0;
          cursor: pointer;

          .image_10 {
            width: 28px;
            height: 32px;
          }

          .text_7 {
            color: #2a7ee7;
            line-height: 15px;
          }

          .text_8 {
            line-height: 15px;
          }


          .image_11 {
            width: 30px;
            height: 32px;
          }

          .text_9 {
            line-height: 15px;
          }

          .pos_18 {
            position: absolute;
            left: 31px;
            bottom: 0;
          }

          .text_10 {
            line-height: 15px;
          }

          .text_11 {
            line-height: 15px;
          }
        }
      }

      .equal-division-item_2 {
        padding-bottom: 10px;
      }
    }

    .group_23 {
      margin-top: -101px;
      padding: 0 239px;

      .section_11 {
        background-color: #ffffff;
        box-shadow: 0px 9px 16px #3838381a;

        .section_12 {
          padding: 64px 63px 63px 57px;
          flex: 1 1 720px;
          background-color: #ffffff;

          .text_12 {
            color: #2c89fd;
            line-height: 24px;
            letter-spacing: 2.5px;
          }

          .text_13 {
            margin-top: 23px;
            line-height: 24px;
          }

          .text_14 {
            margin-top: 40px;
            color: #2c89fd;
            font-size: 20px;
            font-family: MicrosoftYaHei;
            font-weight: 700;
            line-height: 19px;
          }

          .section_17 {
            margin-top: 29px;

            .text_15 {
              margin-left: 13px;
            }
          }


          .image_14 {
            width: 20px;
            height: 17px;
          }


          .group_27 {
            margin-top: 32px;

            .button {
              padding: 12px 0;
              background-color: #2c89fd;
              width: 143px;
              height: 41px;
              cursor: pointer;
            }
          }
        }

        .section_13 {
          flex: 1 1 720px;
          background-image: url('https://ide.code.fun/api/image?token=6572b4a528456c001168e337&name=70885231175d1e6d2d210cb8baf64e61.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;

          .section_15_1 {
            width: 200px;
            height: 200px;
            bottom: 150px;
            position: absolute;
            right: 35px;
            bottom: 150px;
          }

          .section_14 {
            padding: 98px 0 74px 40px;
            background-color: #ffffffdb;

            .image-wrapper_2 {
              padding: 16px 0 96px;
              background-image: url('../../img/9f756189862db795291b8bd16f5a489f.png');
              background-size: 100% 100%;
              background-repeat: no-repeat;
              width: 399px;
              height: 320px;

              .image_12 {
                width: 367px;
                height: 208px;
              }
            }

            .section_15 {
              // width: 144px;
              height: 312px;
            }

            .view {
              margin-left: 28px;
            }

            .pos_19 {
              position: absolute;
              right: 35px;
              bottom: 84px;
            }
          }
        }
      }

      .group_24 {
        position: fixed;
        top: 550px;
        right: 0px;
        z-index: 99;

        .ewm {
          width: 160px;
          height: 160px;
          position: fixed;
          top: 420px;
          right: 80px;
          z-index: 99;
          background: #ccc;
          padding: 20px;
          text-align: center;
          display: none;

          img {
            width: 120px;
            height: 120px;
          }

        }

        .weixinCode:hover {
          .ewm {
            display: block;
          }
        }

        .section_16 {
          padding: 13px 6px 13px 14px;
          background-color: #2c89fd;
          border-radius: 5px 0px 0px 5px;
        }

        .phoneShow {
          display: none;
        }

        .section_16:hover {
          .phoneShow {
            display: block
          }
        }

        .image_15 {
          border-radius: 5px 0px 0px 5px;
          width: 50px;
          height: 50px;
        }

        .pos_20 {
          position: absolute;
          right: 0;
          top: 54px;
        }
      }

      .text_19 {
        margin-top: 80px;
      }

      .image_17 {
        margin-top: 17px;
      }

      .group_28 {
        margin-top: 36px;
        cursor: pointer;

        .button_3 {
          padding: 6px 0;
          background-color: #2c89fd;
          border-radius: 14px;
          width: 108px;
          height: 28px;
        }

        .text-wrapper_2:hover {
          .font_13 {
            color: #2c89fd
          }
        }

        .text-wrapper_2 {
          padding: 6px 0;
          background-color: #ffffff;
          border-radius: 14px;
          width: 108px;
          height: 28px;
          border-left: solid 1px #999999;
          border-right: solid 1px #999999;
          border-top: solid 1px #999999;
          border-bottom: solid 1px #999999;

          .text_20 {
            line-height: 15px;
          }
        }
      }

      .grid {
        margin-top: 26px;
        // width: 1450.5px;
        height: 856px;
        display: grid;
        grid-template-rows: repeat(2, minmax(0, 1fr));
        grid-template-columns: repeat(3, minmax(0, 1fr));
        row-gap: 0;
        column-gap: 12.5px;

        .grid-item {
          padding: 50px 0 10px;
          width: 466px;

          .section_18 {
            margin-right: 15px;
            padding: 245px 16px 0;
            background-color: #ffffff;
            box-shadow: 0px 9px 16px #3838381a;
            width: 466px;
            border-left: solid 1px #dedede;
            border-right: solid 1px #dedede;
            border-top: solid 1px #dedede;
            border-bottom: solid 1px #dedede;

            .image_20 {
              margin-right: 5px;
            }

            .text_21 {
              margin-top: 15px;
            }
          }

          .image_18 {
            width: 466px;
            height: 263px;
          }

          .pos_21 {
            position: absolute;
            left: 0;
            right: 9.5px;
            top: 10px;
          }

          .section_19 {
            margin-right: 10px;
            padding: 245px 16px 0;
            background-color: #ffffff;
            width: 466px;
            border-left: solid 1px #dedede;
            border-right: solid 1px #dedede;
            border-top: solid 1px #dedede;
            border-bottom: solid 1px #dedede;

            .image_21 {
              margin-right: 4px;
              width: 47px;
              height: 18px;
            }

            .text_22 {
              margin-top: 16px;
              line-height: 15px;
            }

            .group_30 {
              padding: 0 2px;

              .image_22 {
                margin-right: 3px;
              }
            }

            .text_23 {
              margin-top: 15px;
            }

            .image_26 {
              margin-right: 4px;
              width: 21px;
              height: 18px;
            }

            .text_26 {
              margin-top: 16px;
              line-height: 15px;
            }

            .font_20 {
              font-size: 18px;
              font-family: MicrosoftYaHei;
              line-height: 16px;
              color: #333333;
            }

            .text_24 {
              line-height: 17px;
            }

            .image_27 {
              margin-right: 4px;
            }

            .text_27 {
              margin-top: 16px;
              line-height: 15px;
            }

            .text_25 {
              line-height: 17px;
            }

            .image_28 {
              margin-right: 5px;
            }

            .text_28 {
              margin-top: 16px;
              line-height: 15px;
            }
          }

          .group_31 {
            margin-top: 21px;
            padding: 16px 0;
            border-top: solid 1px #dedede;
            cursor: pointer;

            .image_23 {
              margin-right: 4px;
            }

            .image_24 {
              margin-right: 4px;
            }

            .image_25 {
              margin-right: 5px;
            }

            .image_29 {
              margin-right: 4px;
            }

            .image_30 {
              margin-right: 4px;
            }

            .image_31 {
              margin-right: 5px;
            }
          }

          .pos_22 {
            position: absolute;
            left: 0;
            right: 9.5px;
            top: 10px;
          }

          .image_19 {
            height: 18px;
          }

          .pos_23 {
            position: absolute;
            left: 0;
            right: 9.5px;
            top: 10px;
          }

          .pos_24 {
            position: absolute;
            left: 0;
            right: 9.5px;
            top: 10px;
          }

          .pos_25 {
            position: absolute;
            left: 0;
            right: 9.5px;
            top: 10px;
          }

          .pos_26 {
            position: absolute;
            left: 0;
            right: 9.5px;
            top: 10px;
          }
        }
      }

      .font_15 {
        font-size: 16px;
        font-family: MicrosoftYaHei;
        line-height: 16px;
        color: #666666;
      }
    }

    .font_7 {
      font-size: 24px;
      font-family: MicrosoftYaHei;
      line-height: 36px;
      font-weight: 700;
      color: #333333;
    }
  }

  .font {
    font-size: 24px;
    font-family: MicrosoftYaHei;
    line-height: 36px;
    font-weight: 700;
    color: #ffffff;
  }

  .font_5 {
    font-size: 48px;
    font-family: Source Han Sans CN;
    line-height: 45px;
    color: #333333;
  }

  .font_4 {
    font-size: 18px;
    font-family: MicrosoftYaHei;
    line-height: 18px;
    color: #333333;
  }

  .group_32 {
    margin-top: 60px;
    height: 880px;

    .section_20 {
      padding: 80px 161px 46px 239px;
      background-image: url('../../img/7be7012a0cb102bd771a378c0b3bdfe3.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;

      .font {
        font-size: 42px;
        font-family: Source Han Sans CN;
        line-height: 40px;
        color: #ffffff;
      }


      .font_2 {
        font-size: 16px;
        font-family: PingFang SC;
        line-height: 16px;
        color: #ffffff;
      }

      .text_2 {
        margin-top: 28px;
        line-height: 17px;
      }

      .group {
        margin-top: 40px;


        .group_3 {
          margin-top: 10px;
          padding: 0 73px;
          border-left: 1px solid #fff;
          margin-left: 80px;

          .font_3 {
            font-size: 30px;
            font-family: PingFang SC;
            line-height: 30px;
            color: #ffffff;
          }

          .text_3 {
            line-height: 17px;
          }

          .group_4 {
            padding: 0 4px;

            .group_7 {
              width: 189px;

              .image_2 {
                width: 54px;
                height: 46px;
              }

              .text_5 {
                margin-top: 29px;
              }

              .group_9 {
                margin-top: 18px;
                line-height: 20px;
                text-align: center;
              }
            }

            .view {
              margin-top: 4px;
            }

            .font_5 {
              font-size: 16px;
              font-family: PingFang SC;
              line-height: 16px;
              font-weight: 500;
              color: #ffffff;
            }

            .font_6 {
              font-size: 14px;
              font-family: PingFang SC;
              line-height: 20px;
              color: #ffffff;
            }

            .font_7 {
              font-size: 14px;
              font-family: PingFang SC;
              line-height: 24px;
              color: #ffffff;
            }

            .image {
              width: 52px;
              height: 52px;
            }
          }
        }
      }



      .text-wrapper {
        padding: 17px 0;
        width: 200px;
        cursor: pointer;
      }

      .text-wrapper:hover {
        .font_4 {
          color: #2c89fd;
        }
      }

      .text-wrapper_choose {
        padding: 17px 0;
        background-color: #2c89fd;
        width: 200px;
      }

      .font_4 {
        font-size: 20px;
        font-family: PingFang SC;
        line-height: 20px;
        color: #ffffff;
      }

      .text_4 {
        margin-top: 71px;
      }

      .text_9 {
        margin-top: 119px;
      }
    }

  }

  .font_10 {
    font-size: 48px;
    font-family: Source Han Sans CN;
    line-height: 45px;
    color: #ffffff;
  }

  .image_16 {
    border-radius: 1.5px;
    width: 196px;
    height: 3px;
  }

  .font_17 {
    font-size: 16px;
    font-family: MicrosoftYaHei;
    letter-spacing: 1.5px;
    line-height: 16px;
    color: #ffffff;
  }

  .font_17_1 {
    font-size: 16px;
    font-family: MicrosoftYaHei;
    letter-spacing: 1.5px;
    line-height: 16px;
    color: #ffffff;
  }


  .font_12 {
    font-size: 14px;
    font-family: MicrosoftYaHei;
    line-height: 12px;
    font-weight: 700;
    color: #ffffff;
  }

  .image_9 {
    width: 24px;
    height: 10px;
  }

  .font_14 {
    font-size: 16px;
    font-family: MicrosoftYaHei;
    line-height: 16px;
    color: #ffffff;
    border-bottom: 1px solid #fff;
    padding-bottom: 15px;
  }

  .font_13 {
    font-size: 16px;
    font-family: MicrosoftYaHei;
    line-height: 16px;
    color: #333333;
  }

  .font_18 {
    font-size: 14px;
    font-family: MicrosoftYaHei;
    line-height: 12px;
    font-weight: 700;
    color: #999999;
  }

  .font_6 {
    font-size: 16px;
    font-family: MicrosoftYaHei;
    line-height: 30px;
    color: #666666;
  }

  .font_11 {
    font-size: 16px;
    font-family: MicrosoftYaHei;
    line-height: 30px;
    color: #ffffff;
  }

  .font_16 {
    font-size: 16px;
    font-family: MicrosoftYaHei;
    line-height: 12px;
    color: #ffffff;
  }

  .group_47 {
    margin-top: 70px;

    .group_50 {
      margin-top: -277px;
      padding-top: 350px;

      .section_26 {
        background-image: url('../../img/b0629d124972709aa3a15de27bc7cc86.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;

        .section_27 {
          padding: 279px 240px 143px;
          background-color: #161515e0;

          .image_48 {
            margin-top: 16px;
          }

          .text_59 {
            margin-top: 26px;
            line-height: 15px;
          }

          .ewmCode {
            width: 130px;
            height: 130px;
            position: absolute;
            top: 5px;
            left: 35%;
            display: none;
          }

          .equal-division-item_4 {
            flex: 1 1 230px;


            .image_49 {
              width: 32px;
              height: 32px;
            }

            .font_34 {
              font-size: 16px;
              font-family: Source Han Sans CN;
              letter-spacing: 1.5px;
              line-height: 16px;
              color: #ffffff;
              cursor: pointer;
              text-align: center;
            }

            .font_34_1 {
              font-size: 16px;
              font-family: Source Han Sans CN;
              letter-spacing: 1.5px;
              line-height: 16px;
              color: #ffffff;
              text-align: center;
            }

            .text_62 {
              line-height: 12px;
              display: none;
            }

            .text_61 {
              line-height: 17px;
            }
          }

          .section_28 {
            padding: 34px 0;
            background-color: #464646;
            height: 140px;
          }

          .section_28:hover {
            background-color: #2c89fd;

            .text_62 {
              display: block;
            }

            .text_62_1 {
              display: none;
            }
          }

          .text_62_1 {
            display: block;
          }

          .section_29 {
            padding: 36px 0;
            background-color: #464646;
            height: 140px;
          }

          .equal-division-item_5 {
            padding: 34px 0 37px;
            background-color: #464646;
            height: 140px;
          }

          .equal-division-item_5:hover {
            background-color: #2c89fd;

            .codeShow {
              display: none;
            }

            .ewmCode {
              display: block;
            }
          }



          .section_30 {
            padding: 34px 24px 21px 30px;
            background-color: #464646;
            height: 140px;
          }
        }
      }

      .section_25 {
        padding: 52px 240px 77px;
        background-color: #ffffff;

        .image_44 {
          margin-top: 17px;
        }

        .text_51 {
          margin-top: 26px;
          line-height: 15px;
        }

        .image_47 {
          width: 54px;
          height: 54px;
        }

        .font_31 {
          font-size: 20px;
          font-family: MicrosoftYaHei;
          line-height: 18px;
          color: #333333;
        }

        .text_52 {
          margin-top: 29px;
          line-height: 19px;
          color: #333333;
        }

        .group_55 {
          margin-top: 18px;
          line-height: 28px;
          text-align: center;

          .font_33 {
            font-size: 16px;
            font-family: MicrosoftYaHei;
            line-height: 28px;
            color: #333333;
          }
        }

        .group_51 {
          margin-right: 45px;

          .text_53 {
            margin-top: 29px;
            line-height: 19px;
          }

          .group_56 {
            margin-top: 18px;
            line-height: 28px;
            text-align: center;
          }
        }

        .group_52 {
          margin-top: 167px;

          .text_54 {
            margin-top: 29px;
            line-height: 19px;
          }

          .group_57 {
            margin-top: 18px;
            line-height: 28px;
            text-align: center;
          }

          .group_53 {
            margin-right: 56px;

            .image_45 {
              width: 61px;
              height: 56px;
            }

            .text_55 {
              margin-top: 29px;
            }

            .group_58 {
              margin-top: 17px;
              line-height: 28px;
              text-align: center;
            }
          }



          .text_56 {
            margin-top: 29px;
          }

          .group_59 {
            margin-top: 17px;
            line-height: 28px;
            text-align: center;
          }
        }

        // .group_54 {
        //   margin-top: 167px;

        //   .image_46 {
        //     width: 66px;
        //     height: 56px;
        //   }

        //   .text_57 {
        //     margin-top: 29px;
        //   }

        //   .text_58 {
        //     margin-top: 24px;
        //   }

        //   .text_58:hover {
        //     color: #2c89fd;
        //   }
        // }

        .font_32 {
          font-size: 20px;
          font-family: MicrosoftYaHei;
          line-height: 20px;
          color: #333333;
        }
      }

      .pos_30 {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
      }
    }

    .section_31 {
      padding: 58px 240px 32px;
      background-color: #222222;

      .group_62 {
        width: 274px;

        .image_50 {
          width: 55px;
          height: 55px;
        }

        .text_69 {
          margin-top: 14px;
          font-weight: 700;
          line-height: 21px;
        }

        .text_73 {
          margin-top: 18px;
          color: #ffffff;
          font-size: 30px;
          font-family: MicrosoftYaHei;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 3px;
          white-space: nowrap;
        }

        .group_66 {
          margin-top: 36px;
          line-height: 20px;

          .text_77 {
            line-height: 20px;
          }

          .text_82 {
            margin-left: 43px;
            line-height: 24px;
          }
        }
      }

      .section_32 {
        background-color: #ffffff1f;
        width: 1px;
        height: 200px;
      }

      .font_35 {
        font-size: 16px;
        font-family: MicrosoftYaHei;
        letter-spacing: 1.5px;
        line-height: 16px;
        font-weight: 700;
        color: #ffffff;
      }

      .text_66 {
        margin-top: 32px;
      }

      .text_70 {
        margin-top: 23px;
      }

      .text_74 {
        margin-top: 22px;
      }

      .text_80 {
        margin-top: 23px;
      }

      .text_64 {
        line-height: 17px;
      }

      .text_67 {
        margin-top: 33px;
        line-height: 15px;
      }

      .text_71 {
        margin-top: 23px;
      }

      .text_75 {
        margin-top: 23px;
        line-height: 15px;
      }

      .text_81 {
        margin-top: 24px;
        line-height: 15px;
      }

      .group_63 {
        margin-top: 15px;

        .text_65 {
          line-height: 17px;
        }

        .text_68 {
          margin-top: 33px;
          line-height: 15px;
        }

        .text_72 {
          margin-top: 24px;
          line-height: 15px;
        }

        .text_76 {
          margin-top: 23px;
          line-height: 15px;
        }
      }

      .group_64 {
        width: 130px;

        .group_65 {
          opacity: 0.4;
          height: 130px;
          border-left: dashed 1px #ffffff;
          border-right: dashed 1px #ffffff;
          border-top: dashed 1px #ffffff;
          border-bottom: dashed 1px #ffffff;
        }

        .image_52 {
          width: 106px;
          height: 106px;
        }

        .pos_33 {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }

        .image_51 {
          width: 130px;
          height: 130px;
        }

        .pos_31 {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
        }

        .image-wrapper_3 {
          padding: 41px 0;
          background-image: url('../../img/4b64faa5aab9acfe49510fdf544c4ae3.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          width: 106px;
        }

        .pos_34 {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }

        .pos_32 {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
        }
      }

      .group_67 {
        padding: 0 20px;

        .font_36 {
          font-size: 16px;
          font-family: MicrosoftYaHei;
          letter-spacing: 1.5px;
          line-height: 16px;
          color: #dfdfdf;
        }

        .text_78 {
          line-height: 15px;
        }

        .text_79 {
          line-height: 15px;
        }
      }
    }

    .text-wrapper_4 {
      padding: 24px 0;
      background-color: #1b1b1b;

      .text_83 {
        line-height: 17px;
      }
    }
  }

  .font_24 {
    font-size: 18px;
    font-family: MicrosoftYaHei;
    letter-spacing: 2px;
    line-height: 18px;
    color: #ffffff;
  }

  .image_35 {
    width: 56px;
    height: 56px;
  }

  .font_28 {
    font-size: 16px;
    font-family: MicrosoftYaHei;
    line-height: 28px;
    color: #333333;
  }


  .font_21 {
    font-size: 16px;
    font-family: MicrosoftYaHei;
    letter-spacing: 1.5px;
    line-height: 16px;
    color: #2c89fd;
  }

  .font_22 {
    font-size: 20px;
    font-family: MicrosoftYaHei;
    letter-spacing: 2px;
    line-height: 20px;
    color: #ffffff;
  }

  .image_13 {
    width: 24px;
    height: 24px;
  }

  .font_19 {
    font-size: 16px;
    font-family: MicrosoftYaHei;
    line-height: 16px;
    color: #999999;
  }


}

.LLM {
  margin-top: 80px;

  .text {
    color: #333333;
    font-size: 48px;
    font-family: Source Han Sans CN;
    line-height: 45px;
  }

  .group {
    margin-top: 17px;

    .image_2 {
      border-radius: 1.5px;
      width: 196px;
      height: 3px;
      margin-bottom: 36px;
    }

    .section {
      padding: 42px 20px 0 28px;
      background-color: #e6f1fc;
      border-radius: 20px;
      height: 391px;
      border-left: solid 1px #ebeff6;
      border-right: solid 1px #ebeff6;
      border-top: solid 1px #ebeff6;
      border-bottom: solid 1px #ebeff6;
      width: 480px;

      .text_2 {
        color: #2c89fd;
        line-height: 18px;
      }

      .font_2 {
        font-size: 16px;
        font-family: PingFang SC;
        line-height: 16px;
        color: #333333;
      }

      .text_4 {
        margin-top: 20px;
        color: #666666;
        line-height: 20px;
      }

      .group_5_1 {
        margin-top: 20px;
        padding-left: 4px;
        padding-right: 2px;

        .image_3 {
          width: 20px;
          height: 20px;
        }

        .group_7 {
          margin-top: 3px;
        }

        .image_9 {
          width: 72px;
          height: 78px;
        }

        .group_6 {
          padding-top: 70px;
          width: 160px;

          .image_8 {
            width: 158px;
            height: 171px;
          }

          .image_4 {
            width: 157px;
            height: 147px;
          }

          .pos {
            position: absolute;
            left: 3px;
            right: 0;
            top: 0;
          }
        }
      }
    }

    .section_2 {
      padding: 44px 0 90px 25px;
      border-radius: 20px;
      background-image: url('../../img/b36f9f707422872fe7e68d58cea3fa00.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      width: 271px;
      height: 391px;

      .group_2_1 {
        margin-top: 13px;
        line-height: 28px;
        margin-right: 21px;

        .font_3 {
          font-size: 16px;
          font-family: PingFang SC;
          line-height: 28px;
          color: #ffffff;
        }
      }

      .image_5 {
        margin-top: 40px;
        width: 70px;
        height: 124px;
      }
    }

    .font {
      font-size: 24px;
      font-family: PingFang SC;
      letter-spacing: 2.5px;
      line-height: 24px;
      font-weight: 500;
      color: #ffffff;
    }

    .section_3 {
      padding: 44px 21px 85px 23px;
      border-radius: 20px;
      background-image: url('../../img/b2d1c41e53d06c2e719bd7bb48596939.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      width: 271px;
      height: 391px;

      .group_3 {
        margin-top: 15px;
        line-height: 24px;
      }

      .image_7 {
        margin-right: 30px;
        margin-top: 30px;
        width: 90px;
        height: 121px;
      }
    }

    .section_4 {
      padding: 45px 21px 90px 25px;
      border-radius: 20px;
      background-image: url('../../img/5e855467bdbd55131b932a00f8827c5c.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      width: 271px;
      height: 391px;

      .text_3 {
        line-height: 23px;
      }

      .group_4 {
        margin-top: 15px;
        line-height: 24px;
      }

      .image_6 {
        margin-right: 9px;
        margin-top: 52px;
        width: 130px;
        height: 118px;
      }
    }

    .font_4 {
      font-size: 16px;
      font-family: PingFang SC;
      line-height: 24px;
      color: #ffffff;
    }

    .guidance:hover {
      color: #2a7ee7;
    }

    .section_5 {
      flex: 1 1 346px;

      .image_10 {
        width: 250px;
        height: 68px;
      }

      .section_6 {
        padding: 20px 0 22px;
        background-color: #f0f8ff;
        border-radius: 20px;
        background-image: none;

        .group_8 {
          line-height: 24px;
          text-align: center;
        }
      }

      .section_7 {
        padding: 21px 0;
        background-color: #f0f8ff;
        border-radius: 20px;

        .group_9 {
          line-height: 24px;
          text-align: center;
        }
      }

      .font_5 {
        font-size: 16px;
        font-family: PingFang SC;
        line-height: 24px;
        color: #809dba;
      }

      .text-wrapper {
        padding: 36px 0 37px;
        background-color: #f0f8ff;
        border-radius: 20px;

        .text_5 {
          line-height: 17px;
        }
      }
    }

    .equal-division-item {
      padding: 58px 19px 20px;
      background-color: #e6f1fc;
      border-radius: 20px;
      // height: 270px;
      height: 180px;
      border-left: solid 1px #ebeff6;
      border-right: solid 1px #ebeff6;
      border-top: solid 1px #ebeff6;
      border-bottom: solid 1px #ebeff6;
    }
  }

  .image_11 {
    width: 100vw;
    height: 244.375vw;
  }
}</style>